import { dLayer } from '@/utils/helpers';

export const authAnalytic = {
  /**
   * @description Когда пользователь видит форму авторизации, отправляется событие
   */
  loginStart() {
    dLayer({
      event: 'interaction',
      site_section: 'login',
      action: 'login_start',
    });
  },

  /**
   * @description После того как пользователь заполнил поля и кликнул на “Войти” и успешно авторизовался, отправляется событие
   */
  loginSuccess() {
    dLayer({
      event: 'interaction',
      site_section: 'login',
      action: 'login_success',
    });
  },

  /**
   * @description Если возникает ошибка, то отправляется событие
   */
  loginError() {
    dLayer({
      event: 'interaction',
      site_section: 'login',
      action: 'step_error',
    });
  },

  /**
   * @description Когда пользователь кликает на кнопку “Зарегистрироваться”, отправляется событие
   */
  registrationStart() {
    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'registration_start',
    });
  },

  /**
   * @description После того как пользователь все верно заполняет на первом шаге и нажимает кнопку “Продолжить” (отправка события должна происходить только при корректном заполнении всех необходимых полей), отправляется событие
   */
  registrationPersonDataSuccess() {
    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'person_data_success',
      eventContext: 'person_data',
    });
  },

  /**
   * @description После того как пользователь успешно звонит на номер и переходит на след.шаг, отправляется событие
   */
  registrationMobileVerificationSuccess() {
    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'mobile_verification_success',
      eventContext: 'mobile_verification',
    });
  },

  /**
   * @description После успешной отправки формы с цифрами ( билайна по обратному звонку), необходимо отправлять событие
   */
  registrationPassportDataSuccess() {
    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'passport_data_success',
      eventContext: 'passport_data',
    });
  },

  /**
   * @description Когда пользователь УСПЕШНО подтвердил email и оказался на странице установки нового пароля, должно отправляться событие:
   */
  registrationEmailVerificationSuccess() {
    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'email_verification_success',
      eventContext: 'email_verification',
    });
  },

  /**
   * @description Когда пользователь установил пароль и кликнул на кнопку “Сохранить”, отправляя форму, должно отправляться событие
   */
  registrationPasswordSuccess() {
    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'password_success',
      eventContext: 'password',
    });
  },

  /**
   * @description После успешного завершения регистрации необходимо отправлять событие
   * @param {string} type - тип
   */
  registrationSuccess(type) {
    const label =
      type === 'finish'
        ? 'Благодарим за регистрацию!'
        : 'Ваш аккаунт на проверке';

    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'registration_success',
      label: label,
    });
  },

  /**
   * @description Если на каком-либо шаге возникает ошибка, то отправляется событие:
   * @param {string} nameField - название поля с ошибкой
   * @param {string} step - название шага
   */
  registrationError(nameField, step) {
    dLayer({
      event: 'interaction',
      site_section: 'registration',
      action: 'step_error',
      label: nameField,
      eventContext: step,
    });
  },
};

export default {
  authAnalytic,
};
